import React from 'react'
import { withUnpublishedPreview } from 'gatsby-source-prismic'
import { Link, graphql } from 'gatsby'
import tw from 'tailwind.macro'
import styled from 'styled-components'
import NoTranslation from '../components/Templates/NoTranslation'
import SEO from '../components/General/seo'
import Button from '../components/General/Buttons/Button'
import AlgorandLogoBlack from '../components/Shapes/AlgorandLogoBlack'

// templates
import ArticleTemplate from '../templates/article'
import PageTemplate from '../templates/page'

const Wrap = styled.div`
  min-height: 100vh;
  ${tw`py-8 px-3`}
`

const Body = styled.div`
  p {
    ${tw`mb-4 leading-normal text-dark-grey-1 text-lg`};
  }
`

const NotFoundPage = ({ data }) => {
  if (!data.page) return <NoTranslation></NoTranslation>

  return (
    <Wrap>
      <SEO title="Not Found" />
      <div className="font-sans text-center max-w-md mx-auto">
        <Link
          to="/"
          className="mb-4 md:mb-10 block text-charcoal max-w-xs mx-auto"
        >
          <AlgorandLogoBlack />
        </Link>
        <div
          className="mb-4 md:mb-8 h-px bg-magenta mx-auto"
          css="width: 100px"
        ></div>
        <h1 className="font-black text-5xl md:text-6xl uppercase">
          {data.page.data.sorry_title.text}
        </h1>
        <p className="mb-4 text-2xl md:text-4xl font-bold">
          {data.page.data.sorry_subtitle.text}
        </p>
        <Body
          dangerouslySetInnerHTML={{ __html: data.page.data.body_text.html }}
        />
        <div className="w-full mx-auto" css="max-width: 300px;">
          {data.page.data.links.map((link) => (
            <Link to={link.link.url} className="no-underline">
              <Button className="w-full mb-2 justify-center no-underline">
                {link.link_text.text}
              </Button>
            </Link>
          ))}
        </div>
      </div>
    </Wrap>
  )
}

export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    article: ArticleTemplate,
    page: PageTemplate,
  },
})

export const pageQuery = graphql`
  query NotFoundPage($locale: String!) {
    page: prismicNotFound(lang: { eq: $locale }) {
      data {
        title {
          text
        }
        seo_keywords {
          keyword {
            text
          }
        }
        seo_description {
          text
        }
        sorry_title {
          text
        }
        sorry_subtitle {
          text
        }
        body_text {
          html
        }
        links {
          link {
            url
          }
          link_text {
            text
          }
        }
      }
    }
  }
`
